/* eslint-disable react/jsx-no-bind */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

// Lib UI components

// Local UI components
import Seo from '../shared/Seo';

const PageLayout = React.lazy(() => import('../shared/PageLayout'));
const CreateCompanyMainView = React.lazy(() => {
  return import('../page-components/CreateCompany/CreateCompanyMainView');
});
const CreateCompanyForm = React.lazy(() => {
  return import('../page-components/CreateCompany/CreateCompanyForm');
});
// Style

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function CreateCompanyPage({ location }) {
  const isSSR = typeof window === 'undefined';
  /* ********************************** HOOKS ********************************* */
  const { navigate } = useI18next();
  const params = new URLSearchParams(location.search);
  const steps = params.get('step') ?? 0;

  // Localization
  function goNext() {
    navigate(`/create-company?step=${+steps + 1}`);
  }

  function goBack() {
    navigate(`/create-company?step=${+steps - 1}`);
  }

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Seo title="Create a company" />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <PageLayout>
            <div className="create-company">
              {+steps === 0 && <CreateCompanyMainView goNext={goNext} />}
              {+steps > 0 && (
                <CreateCompanyForm
                  steps={steps}
                  goBack={goBack}
                  goNext={goNext}
                />
              )}
            </div>
          </PageLayout>
        </React.Suspense>
      )}
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["CreateCompany", "CreateCompanyForm", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

CreateCompanyPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default CreateCompanyPage;
